<script setup lang="ts">
import type { Project_Plain } from '@webamboos/admin'
defineProps<{
  project: Project_Plain
}>()
const localePath = useLocalePath()
</script>

<template>
  <div class="bordershadow-medium group !h-96 w-full overflow-hidden rounded-lg object-contain">
    <NuxtLink :to="localePath(`/projects/${project.industries[0].slug}/${project.slug}`)"
      ><div
        :class="[
          'absolute inset-0 flex h-full w-full flex-col items-start justify-end gap-y-4 p-8',
          'rounded-lg bg-white/60 opacity-0 backdrop-blur-md ease-in-out',
          'transition-all duration-500 group-hover:opacity-100 group-hover:duration-300',
        ]"
      >
        <div class="flex max-w-[264px] flex-col gap-y-2 text-left sm:max-w-none">
          <div v-if="project.industries?.length" class="mb-2 flex gap-1">
            <Paragraph
              v-for="industry of project.industries"
              :key="industry.name"
              size="small"
              weight="medium"
              align="left"
              class="bg-main-100 w-max rounded-[36px] px-2 pb-[3px] pt-1 !text-gray-900"
            >
              {{ industry.name }}
            </Paragraph>
          </div>

          <Heading as="h3" size="medium" weight="medium">
            {{ project.title }}
          </Heading>

          <Paragraph size="normal" weight="normal" align="left" class="!text-gray-900">
            {{ project.description }}
          </Paragraph>

          <div v-if="project.country_name" class="rounded-[36px]">
            <Paragraph
              size="small"
              weight="medium"
              align="left"
              class="bg-main-100 inline-flex items-center gap-1 rounded-[36px] px-2 pb-[3px] pt-1 !text-gray-900"
            >
              {{ project.country_name }}

              <Icon :name="project.country_flag || ''" />
            </Paragraph>
          </div>
        </div>

        <p
          class="text-main-800 group/link hover:bg-main-500/10 -m-2 flex items-center justify-start gap-4 rounded p-2 pr-4 text-sm font-medium transition"
        >
          {{ $t('home.projects.more') }}
          <Icon name="lucide:move-right" size="18" class="transition group-hover/link:translate-x-1" />
        </p>
      </div>

      <NuxtPicture
        v-if="project.image"
        :src="project.image.url"
        :alt="project.title"
        :width="640"
        :height="400"
        format="webp"
        densities="x1 x2"
        sizes="xs:100vw sm:100vw md:640px"
        loading="lazy"
        :placeholder="project.image.formats?.thumbnail.url"
        fit="cover"
        :img-attrs="{
          class:
            'h-full rounded-lg object-cover transition-all duration-500 group-hover:duration-300 ease-in-out z-[-1] relative group-hover:scale-110',
        }"
    /></NuxtLink>
  </div>
</template>
